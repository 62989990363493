:root {
  --primary-deep-ash: #302e34;
  --secondary-sand: #fff2d8;
  --deep-ash: #45404b;
  --dark-gray: #98969a;
  --light-gray: #cbcacc;
  --error: #e94e4e;
}

body {
  margin: 0;
  font-family: 'Lato', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #e5e5e5;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.015em;
}

h1 {
  font-family: Quicksand;
  font-style: normal;
  font-weight: 300;
  font-size: 62px;
  line-height: 64px;
  letter-spacing: 0.015em;
}
h2 {
  margin-top: 80px;
  font-family: Quicksand;
  font-style: normal;
  font-weight: 300;
  font-size: 48px;
  line-height: 50px;
  letter-spacing: 0.015em;
  color: #302e34;
}

p {
  margin: 0;
}
a {
  color: inherit;
  text-decoration: none;
}

header {
  background-color: var(--primary-deep-ash);
  height: 152px;
  color: var(--light-gray);
}
.header-links {
  display: flex;
  max-width: 1150px;
  padding: 14px 0px;
  margin: auto;
  justify-content: space-between;
  justify-self: center;
}
.header-link-container {
  margin-left: 16px;
  display: flex;
  width: 25%;
  min-width: 280px;
  justify-content: space-between;
}
.header-link-container > p {
  cursor: pointer;
}
header img {
  display: block;
  margin: auto;
  margin-top: 21px;
}

.web-menu {
  width: 100%;
  max-width: 1150px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.015em;
  text-transform: uppercase;
  border-bottom: 1px solid var(--primary-deep-ash);
}
.menu-item {
  margin: 0 12px;
  padding: 20px 0;
  border-bottom: 2px solid transparent;
  white-space: nowrap;
}
.dropdown-conatiner {
  position: relative;
}
.menu-item:hover {
  font-weight: 900;
  border-bottom: 2px solid var(--primary-deep-ash);
}
.web-menu-dropdown {
  z-index: 1;
  background: #ffffff;
  display: none;
  font-weight: 400;
  position: absolute;
  top: 209px;
  width: 198px;
  border: 2px solid #cbcacc;
}
.menu-item:hover .web-menu-dropdown {
  display: block;
}
.web-menu-dropdown a {
  display: block;
  padding: 0 20px;
  margin: 16px 0;
}
.web-menu-dropdown a:hover {
  font-weight: 900;
  border: none;
}

.header-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.header-img {
  width: 100%;
}
.header-title {
  position: absolute;
  max-width: 990px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  bottom: -185px;
}
.header-title h1 {
  padding: 24px;
  max-width: 660px;
  background-color: #e5e5e5;
}
.content {
  margin: 0 auto;
  max-width: 990px;
  margin-top: 150px;
  text-align: left;
}
.page {
  margin: 0 auto;
  margin-top: 150px;
  max-width: 990px;
  margin-bottom: 200px;
}
.grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 58px;
  padding-left: 24px;
  width: 100%;
}
.container {
  grid-column-start: 1;
  grid-column-end: 2;
  max-width: 465px;
  width: 100%;
}

.container h5 {
  margin: 0;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.015em;
  color: #302e34;
}
.nok {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  letter-spacing: 0.015em;
  color: #302e34;
}

.selected-language {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  letter-spacing: 0.015em;
  color: #45404b;
}
.dropdown {
  width: 190px;
  background: #ffffff;
  border: 1px solid #302e34;
  box-sizing: border-box;
  border-radius: 4px;
  height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 16px;
  padding-right: 24px;
  margin: 10px 0;
  position: relative;
  cursor: pointer;
}
.dropdown img {
  transition: transform 0.25s ease-out;
}

.dropdown-content {
  position: absolute;
  top: 61px;
  background: #ffffff;
  border: 1px solid black;
  box-sizing: border-box;
  text-align: left;
  left: -0;
  width: 100%;
  height: 0;
  border-radius: 4px;
  z-index: 1;
  overflow: hidden;
  padding: 0 17px;
  transition: height 0.35s ease-out;
}
.dropdown-content p {
  height: 64px;
  line-height: 62px;
  border-bottom: 1px solid #98969a;

  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  letter-spacing: 0.015em;
  color: #45404b;
}
.dropdown-content p:last-child {
  border-bottom: none;
}
.dropdown-content p:hover,
.dropdown-content p:focus {
  background: #45404b;
  color: #ffffff;
  margin-left: -16px;
  padding-left: 16px;
  width: calc(100% + 16px);
  outline: none;
}

.pricetable {
  border: 1px solid #302e34;
  box-sizing: border-box;
  max-width: 390px;
  width: 100%;
  max-height: 740px;
  background: #ffffff;
  grid-column-start: 2;
  grid-row-start: 2;
  justify-self: end;
}
.pricetable-header-container {
  height: 94px;
  background: #302e34;
}
.pricetable-header-container p {
  padding-top: 32px;
  margin-left: 24px;
  font-family: Lato;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.015em;
  text-transform: uppercase;
  color: #ffffff;
}
.pricetable-header-container h5 {
  margin: 0;
  margin-left: 24px;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.015em;
  color: #fff2d8;
}
.pricelist-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 28px;
  margin-right: 28px;
}
.pricelist-item h4 {
  font-size: 28px;
}
.pricelist-item:not(:last-child) {
  border-bottom: 1px solid #cbcacc;
}
.pricetable-subtext {
  font-family: Lato;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.015em;
  color: #838285;
}

.delivery-container {
  margin: 0 25px;
}

.delivery-time {
  text-align: center;
  padding: 40px 0;
  margin: 0;
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 34px;
  text-align: center;
  letter-spacing: 0.015em;
  color: #302e34;
}
.photo-container {
  width: 100%;
  max-width: 489px;
}
.store-photo {
  margin-top: 24px;
  width: 100%;
}

.footer {
  border-top: 1px solid #98969a;
  margin: 0 auto;
  padding-top: 80px;
  margin-bottom: 80px;
  max-width: 990px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 40px;
}
.footer a {
  display: block;
}
.footer-start {
  justify-self: start;
}
.footer-center {
  max-width: 158px;
  justify-self: center;
}
.footer-end {
  padding-left: 150px;
}
.footer h5 {
  font-family: Lato;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.015em;
  color: #302e34;
  margin: 8px 0;
}
.mobile-header,
.mobile-menu {
  display: none;
}

@media (max-width: 950px) {
  .web-header {
    display: none;
  }
  .mobile-header {
    position: relative;
    display: flex;
    height: 95px;
    justify-content: center;
    align-items: center;
  }
  .MenuBurger {
    position: absolute;
    left: 20px;
    margin: 0;
  }
  .mobile-logo {
    margin: 0;
  }
  .overlay {
    position: fixed;
    width: 100vw;
    height: 100vh;
    opacity: 0.5;
    background-color: black;
    z-index: 9;
    margin-top: -95px;
  }

  .mobile-menu {
    background-color: white;
    width: 85vw;
    height: 100vh;
    display: flex;
    justify-content: flex-tart;
    position: fixed;
    top: 0;
    left: -100%;
    z-index: 10;
    transition: 0.2s;
  }
  .mobile-menu.active {
    left: 0;
    transition: 0.2s;
  }
  .mobile-content {
    margin: 40px 26px;
  }
  .nav-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: 'Lato', sans-serif;
    font-weight: 300;
    font-size: 16px;
    text-transform: uppercase;
    margin: 30px 0;
  }
  .nav-children {
    padding: 0 15px;
  }

  .footer {
    grid-template-columns: 1fr;
    margin: 0 16px;
    margin-bottom: 80px;
  }
  .footer-start,
  .footer-center,
  .footer-end {
    justify-self: start;
    padding-left: 0;
  }
  .header-link-container {
    display: none;
  }
  .page {
    margin-top: 24px;
    margin-bottom: 100px;
  }
  .grid {
    justify-items: start;
    grid-template-columns: 100%;
    padding: 0;
  }
  .container {
    margin: 0 16px;
    width: 90%;
  }
  .pricetable {
    grid-column-start: 1;
    justify-self: center;
  }
  .web-menu {
    display: none;
  }
  .header-section {
    flex-direction: column-reverse;
    margin: 0 16px;
  }
  .header-title {
    position: static;
    flex-direction: column-reverse;
    align-items: flex-start;
  }
  .header-title h1 {
    padding: 0;
    margin: 32px 0 24px 0;
  }
  .header-img {
    min-height: 416px;
    object-fit: cover;
  }
  .content {
    margin: 16px;
  }
}
